@font-face {
  font-family: "Barlow Condensed, Regular";
  src: url("./fonts/BarlowCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Barlow Condensed, Light";
  src: url("./fonts/BarlowCondensed-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Barlow Condensed, Medium";
  src: url("./fonts/BarlowCondensed-Medium.ttf") format("truetype");
  font-weight: 500;
}

$font-family-sans-serif: "Barlow Condensed, Regular", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$primary: #0d3774;
$secondary: #00d4c0;
$success: #658674;
$info: #c0b3e9;
$warning: #e0eb82;
$danger: #e76e65;
$light: #cccccc;
$dark: #333333;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
);

@import "~bootstrap/scss/bootstrap";

h1, h2, h3, h4, p {
  color: rgb(24, 24, 24);
}

h1 {
  font-family: "Barlow Condensed, Medium";
}

p {
  font-family: "Barlow Condensed, Light";
}

#app {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("./TL_Portal_Background.jpg");
}

// #enter {
//   font-family: "Barlow Condensed, Medium";
// }